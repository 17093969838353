/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-overflow-scrolling: touch;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/DM_Sans/DMSans-Regular.ttf");
}

html {
  font-size: 1px;
}

body {
  font-size: 16rem;
}

html,
body {
  min-height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}


.header-user-icon {
  stroke: var(--header-user-icon);
}

.mintBtn {
  padding: 12px 20px !important;
  text-transform: uppercase !important;
  background: linear-gradient(
    180deg,
    var(--vitreus-luminous-green) 0%,
    var(--vitreus-dark-blue) 100%
  ) !important;
  min-width: unset !important;
  font-size: 12rem !important;
  line-height: 16rem !important;
}

.gradientBg {
  background-image: linear-gradient(
    90deg,
    var(--vitreus-luminous-green) 0%,
    var(--vitreus-dark-blue) 100%
  ) !important;
}

.doMint {
  width: 100% !important;
  height: 100% !important;
}

.mintBtn:hover {
  background: linear-gradient(
    360deg,
    var(--vitreus-luminous-green) -6.25%,
    var(--vitreus-dark-blue) 100%
  ) !important;
}

.oneHundredPercent {
  font-size: 12rem !important;
  line-height: 16rem !important;
  padding: 12px 24px !important;
  border: 1px solid var(--vitreus-luminous-green) !important;
  background: none !important;
  color: var(--vitreus-luminous-green) !important;
  transition: 0.2s !important;
  border-radius: 10px !important;
}

.oneHundredPercent:hover {
  background: none !important;
}

.icon-success path {
  fill: var(--vitreus-luminous-green);
}

.icon-success g rect {
  fill: var(--vitreus-luminous-green);
}

.icon-error path {
  fill: var(--vitreus-red);
}

.icon-error g rect {
  fill: var(--vitreus-red);
}

@media screen and (max-width: 650px) {
  .balanceBtn {
    margin-right: 50% !important;
  }
}

#root {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--skeleton-backgound);
}

/* #root {
  background-image: url('./assets/images/mark-black.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
} */

._lock {
  /* touch-action: none;
  -ms-touch-action: none; */
}

.scrolling {
  -webkit-overflow-scrolling: touch;
}

.bilkBLock {
  background-color: rgba(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  -webkit-box-shadow: 0px 0px 100vh 5vw var(--vitreus-luminous-green);
  -moz-box-shadow: 0px 0px 100vh 5vw var(--vitreus-luminous-green);
  box-shadow: 0px 0px 100vh 5vw var(--vitreus-luminous-green);
}

.bgCover {
  background-size: contain !important;
}
